@tailwind base;
@tailwind components;
@tailwind utilities;

canvas {
    position: fixed;
    pointer-events: none;
    z-index: 100;
    width: 100%;
    height: 100%;
    inset: 0;
  }